<template>
  <div class="editAvatar">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <div class="navTitle">选择头像</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <PullRefresh
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :isHigehtMax="true"
        :isNoData="isNoData"
        :error="error"
      >
        <div class="avatarBox">
          <ImgDecypt
            class="avatarImg"
            :src="selectAcatar"
            round
            :key="selectAcatar + 'avatar'"
          />
          <div class="maskBox">
            <svg-icon class="camera" iconClass="camera" />
          </div>
          <input class="file" type="file" @change="chooseImg" @click.stop />
          <!-- <div class="file" @click="showToast" v-else></div> -->
        </div>
        <div class="uploadTip">点击头像上传头像</div>
        <div class="avatarList">
          <div
            class="avatarItem"
            v-for="item in avatarList"
            :key="item.id"
            @click="changeActive(item)"
          >
            <ImgDecypt
              class="avatarImg"
              :src="item.resource"
              :key="item.resource"
              round
            />
            <svg-icon
              class="selectActive"
              iconClass="selectActive"
              v-if="selectAcatar == item.resource"
            />
          </div>
        </div>
      </PullRefresh>
    </div>

    <div class="confirmBtn" @click="modifyAvatar">确认选择</div>
  </div>
</template>

<script>
import { queryAvatarList } from "@/api/user";
import { upload } from "@/api/app";
import PullRefresh from "@/components/PullRefresh";
import ImgDecypt from "@/components/ImgDecypt";
import { modifyUserInfo } from "@/api/user";
import { Toast } from "vant";
export default {
  components: {
    PullRefresh,
    ImgDecypt,
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 30,
      avatarList: [],
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      error: false,
      selectAcatar: "",
      userInfo: {},
    };
  },
  created() {
    this.userInfo = this.$store.getters.userInfo;
    this.selectAcatar = this.$store.getters.userInfo.portrait;
    this.queryList();
  },
  methods: {
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    async chooseImg(e) {
      let params = new FormData();
      params.append("upload", e.target.files[0]);
      let ret = await this.$Api(upload, params);
      if (ret.code == 200) {
        Toast("上传成功");
        this.selectAcatar = ret.data.coverImg;
      } else {
        Toast(ret.tip || "上传失败");
      }
    },
    // 查询头像列表
    async queryList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        type: "avatar",
      };
      try {
        let res = await this.$Api(queryAvatarList, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          // this.avatarList = res.data.list;
          if (type === "refresh") {
            this.avatarList = list;
          } else {
            this.avatarList = this.avatarList.concat(list);
          }
          if (this.pageNumber == 1 && this.avatarList.length == 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
            return;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },
    onLoad() {
      this.loading = true;
      this.pageNumber++;
      this.queryList();
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.queryList("refresh");
    },
    // 修改头像
    changeActive(item) {
      this.selectAcatar = item.resource;
    },
    showToast() {
      Toast("您还不是VIP，无法上传头像");
    },
    // 修改头像
    async modifyAvatar() {
      let req = {
        portrait: this.selectAcatar,
      };
      let ret = await this.$Api(modifyUserInfo, req);
      if (ret && ret.code == 200) {
        Toast("修改成功");
        this.$router.go(-1);
      } else {
        Toast(ret.tip || "编辑失败，请稍后再试");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.editAvatar {
  height: 100%;
  color: #000;
  background: #f5f5f5;
  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;

    .navArrow {
      width: 17px;
      height: 17px;
    }

    .navTitle {
      // flex: 2;
      // text-align: center;
      font-size: 18px;
    }

    .rightBtn {
      width: 17px;
      height: 17px;
    }
  }

  .main {
    height: calc(100% - 44px);
    .avatarBox {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      margin: 37px auto 9px;
      overflow: hidden;
      position: relative;
      .maskBox {
        width: 100%;
        height: 24px;
        background: rgba(0, 0, 0, 0.6);
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        .camera {
          width: 16px;
          height: 16px;
        }
      }
      .file {
        position: absolute;
        width: 100%;
        height: 100%;
        // background: #000;
        z-index: 100;
        top: 0;
        left: 0;
        opacity: 0;
      }
    }
    .uploadTip {
      font-size: 15px;
      color: #999;
      text-align: center;
      margin-bottom: 30px;
    }
  }

  // /deep/ .refreshing_warp {
  //     height: calc(100% - 170px);
  // }
  /deep/ .van-pull-refresh {
    height: 100%;
  }

  /deep/ .van-list__finished-text {
    display: none;
  }

  .avatarList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: 30px;
    grid-column-gap: 20px;
    padding: 0px 38px 126px;
    box-sizing: border-box;

    .avatarItem {
      width: 60px;
      height: 60px;
      position: relative;

      .avatarImg {
        width: 100%;
        height: 100%;

        /deep/ .vanImage {
          background: #00000000 !important;
        }

        /deep/ .van-image__error {
          background: #00000000 !important;
        }
      }

      .selectActive {
        width: 22px;
        height: 22px;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }

  .confirmBtn {
    width: 164px;
    height: 46px;
    border-radius: 44px;
    line-height: 44px;
    font-size: 18px;
    // margin: 30px auto;
    text-align: center;
    background: #fbd13d;
    color: #424242;
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
